<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="packages"
      :loading="loading"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="getPackagesCount || options.itemsPerPage * totalPages"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-btn color="primary" @click="paginateTo">
                <v-icon>mdi-autorenew</v-icon>
                Оновити дані
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-datetime-picker
                v-model="fromDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                timeFormat="HH:mm:ss"
                dateFormat="dd-MM-yyyy"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час від"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" offset-md="1">
              <v-datetime-picker
                v-model="toDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                dateFormat="dd-MM-yyyy"
                timeFormat="HH:mm:ss"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час до"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="isServerTime" class="mt-0" label="Використовувати серверний час" hide-details></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:header.packet_name="{ header }">
        <TableTitleFilter
          :options="packageTypesDictionary"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (selectedPackageTypes = selected)"
          @ok="applyFilter('terminals')"
        />
      </template>
      <template v-slot:header.terminal_id="{ header }">
        <TableTitleFilter
          :options="terminals"
          :options-meta="terminalsMeta"
          :options-params="terminalsLoadingData"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedTerminals = selected)"
          @ok="applyFilter('terminals')"
        >
          <template v-slot:item="{ item }">T{{ item.id }} ({{ item.imei }})</template>
          <template v-slot:selection="{ item }">T{{ item.id }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.imei="{ header }">
        <TableTitleFilter
          :options="terminals"
          :options-meta="terminalsMeta"
          :options-params="terminalsLoadingData"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedTerminals = selected)"
          @ok="applyFilter('terminals')"
        >
          <template v-slot:item="{ item }">T{{ item.id }} ({{ item.imei }})</template>
          <template v-slot:selection="{ item }">T{{ item.id }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:item.terminal_packet_date_time="{ item }">
        <div>{{ item.terminal_packet_date_time | getShortTime }}</div>
        <span class="caption">{{ item.terminal_packet_date_time | getShortDate }}</span>
      </template>
      <template v-slot:item.server_packet_date_time="{ item }">
        <div>{{ item.server_packet_date_time | getShortTime }}</div>
        <span class="caption">{{ item.server_packet_date_time | getShortDate }}</span>
      </template>
      <template v-slot:item.terminal_id="{ item }">
        <router-link
          :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.terminal_id } }"
          class="text-decoration-none"
        >
          T {{ item.terminal_id }}
        </router-link>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" small @click.stop="openDialogPacket(item.packet, 'info')" v-bind="attrs" v-on="on">
              mdi-package-variant
            </v-icon>
          </template>
          <span>Інфо пакету</span>
        </v-tooltip>
        <v-tooltip v-if="item.errors" bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click.stop="openDialogPacket(item.errors, 'error')" v-bind="attrs" v-on="on" color="error">
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>Переглянути помилку пакету</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="resetData"> Оновити </v-btn>
      </template>
    </v-data-table>

    <LogsPackageModal
      @closePackageDialog="dialogPacket = false"
      :dialogPacket="dialogPacket"
      :dialogPacketType="dialogPacketType"
      :selectedPacket="selectedPacket"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import ConvertDate from '@/mixins/convertDate'
import OpenLogsPackageDialog from '@/mixins/openLogsPackageDialog'
import resetTableData from '@/mixins/resetTableData'
import moment from 'moment'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
  name: 'Packages',

  components: {
    TableTitleFilter,
    LogsPackageModal: () => import('@/components/dialogs/LogsPackageModal'),
  },

  mixins: [ConvertDate, OpenLogsPackageDialog, resetTableData],

  data: () => ({
    MENU_MIN_WIDTH: 450,
    MENU_MAX_WIDTH: 450,
    isInitialized: false,
    packageTypesMenu: false,
    terminalsLoadingData: null,
    loading: false,
    filterLoading: false,
    sortDesc: true,
    sortBy: '',
    options: {},
    page: 1,
    isServerTime: false,
    textFieldProps: {
      hideDetails: 'auto',
      dense: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
    selectedPackageTypes: [],
    selectedTerminals: [],
    selectedPacket: null,
    fromDate: null,
    toDate: null,
    totalPages: 2,
  }),

  computed: {
    ...mapState('logs', ['packages']),
    ...mapState('dictionaries', ['packageTypesDictionary']),
    ...mapState('terminals', ['terminals', 'terminalsMeta']),

    ...mapGetters('logs', ['getPackagesCount']),

    headers() {
      return [
        {
          text: 'Дата і час',
          value: this.isServerTime ? 'server_packet_date_time' : 'terminal_packet_date_time',
          sortable: true,
          width: 120,
        },
        { text: 'Тип пакета', value: 'packet_name', sortable: false },
        { text: 'Термінал', value: 'terminal_id', sortable: false },
        { text: 'IMEI', value: 'imei', sortable: false },
        { text: 'Версія прошивки', value: 'firmware', sortable: false },
        { text: '', value: 'actions', sortable: false, width: '75px', align: 'left' },
      ]
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    sortDirection() {
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] ? 'desc' : 'asc'
      } else return ''
    },
    convertedFromDate() {
      if (!this.fromDate) return ''
      return moment(this.fromDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    convertedToDate() {
      if (!this.toDate) return ''
      return moment(this.toDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    selectedPackageTypesCSV() {
      return this.selectedPackageTypes.map((item) => item.value).join()
    },
    selectedTerminalsCSV() {
      return this.selectedTerminals.map((item) => item.id).join()
    },
  },

  watch: {
    options: {
      handler(val) {
        if (!this.getPackagesCount && val.page === this.totalPages) {
          this.totalPages++
        }
        this.paginateTo()
      },
      deep: true,
    },
    isServerTime: {
      handler(newValue) {
        if (this.packages.length) this.SET_PACKAGES([])
        this.sortBy = newValue ? 'server_packet_date_time' : 'terminal_packet_date_time'
      },
      immediate: true,
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('logs', ['loadAllPackages', 'displayWarningAlert']),
    ...mapActions('terminals', ['loadTerminals']),
    ...mapMutations('logs', ['SET_PACKAGES']),

    async initialize() {
      this.terminalsLoadingData = {
        loadingFunction: this.loadTerminals,
        searchParamName: 'search',
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
      this.isInitialized = true
    },
    async paginateTo() {
      if (!this.isInitialized) return
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          companyId: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          /* *** sort by 'terminal_packet_date_time' =>> 'time' | see CM-511 *** */
          // sort: this.options.sortBy[0],
          direction: this.sortDirection,
          packetTypes: this.selectedPackageTypesCSV,
          terminals: this.selectedTerminalsCSV,
          fromDate: this.convertedFromDate,
          toDate: this.convertedToDate,
          serverTime: this.isServerTime ? 1 : 0,
        }
        await this.loadAllPackages(params)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async applyFilter(type) {
      this[`${type}Menu`] = false
      if (type === 'eventTypes') {
        const payload = {
          eventTypeId: this.selectedEventTypesCSV,
        }
        this.filterLoading = true
        await Promise.all([this.paginateTo(), this.loadAdditionalInfoEventCodes(payload)])
        this[`is${type.capitalizeFirstLetter()}FilterApplied`] =
          !!this[`selected${type.capitalizeFirstLetter()}`].length || this.selectedEventTypeGroups.length
        this.filterLoading = false
        return
      }
      this.packageTypesMenu = false
      await this.paginateTo()
      this[`is${type.capitalizeFirstLetter()}FilterApplied`] = !!this[`selected${type.capitalizeFirstLetter()}`].length
    },
    /*openDialogPacket(data, type) {
      type === 'info' ? (this.selectedPacket = JSON.parse(data)) : (this.selectedPacket = data)
      this.dialogPacketType = type
      this.dialogPacket = true
    },*/
  },
}
</script>

<style scoped></style>
